import React from 'react'
import MediaCard from '../components/mediaCard';
import Head from '../components/head';
import Layout from '../components/layout'

const projects = () => {
    return (
        <div>
        <Layout>
             <Head title = "Projects"/>
            <center> <h2>Full Stack Web Development </h2></center>
             <MediaCard />
        </Layout>
        </div>
    )
}

export default projects
