import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
})

export default function MediaCard() {
  const classes = useStyles()
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <a href="https://hrent.herokuapp.com/" target="_blank">
                <Typography gutterBottom variant="h5" component="h2">
                  Hrent
                </Typography>
              </a>
              <Typography variant="body2" color="textSecondary" component="p">
                Hrent helps users search houses which are for rent. It is build
                with react and google maps.It simultaneously shows the search
                results on the side map.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a href="https://hrent.herokuapp.com/" target="_blank">
              <Button size="small" color="primary">
                Learn More
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <a
                href="https://www.mesbahrafin.me/"
                target="_blank"
              >
                <Typography gutterBottom variant="h5" component="h2">
                Portfolio
                </Typography>
              </a>
              <Typography variant="body2" color="textSecondary" component="p">
               This Portfolio Website is built using React and Material UI on the frontend and
               Gatsby and graphql api to fetch  data from a contentful CMS for blog post. 
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a
              href="https://www.mesbahrafin.me/"
              target="_blank"
            >
              <Button size="small" color="primary">
                Learn More
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <a
                href="https://github.com/dragonite20/mern-social"
                target="_blank"
              >
                <Typography gutterBottom variant="h5" component="h2">
                  MERN social
                </Typography>
              </a>
              <Typography variant="body2" color="textSecondary" component="p">
                It is built with React , Express-Node.js API & mongodb.Just like
                any other social media app it has like,upload photo, comment and
                other similar functionalities.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a
              href="https://github.com/dragonite20/mern-social"
              target="_blank"
            >
              <Button size="small" color="primary">
                Learn More
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <a
                href="https://github.com/dragonite20/Events-Booking-App/"
                target="_blank"
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Easy Event
                </Typography>
              </a>
              <Typography variant="body2" color="textSecondary" component="p">
                Easy Event helps users create event,login,change & show bookings
                & show charts of bookings. It is built React with a
                GraphQL-Node.js API & Mongodb.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a
              href="https://github.com/dragonite20/Events-Booking-App/"
              target="_blank"
            >
              <Button size="small" color="primary">
                Learn More
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <a
                href=" https://github.com/dragonite20/rest-shop-api"
                target="_blank"
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Store Rest API
                </Typography>
              </a>
              <Typography variant="body2" color="textSecondary" component="p">
                Shop Rest API serves user data,prices and number of items as
                well as other data in json. It is built with Express-Node.js
                API,Mongodb and jasonwebtokens.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a
              href=" https://github.com/dragonite20/rest-shop-api"
              target="_blank"
            >
              <Button size="small" color="primary">
                Learn More
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <a
                href="https://github.com/dragonite20/Ecommerce-Dashboard"
                target="_blank"
              >
                <Typography gutterBottom variant="h5" component="h2">
                  E-commerce Dashboard
                </Typography>
              </a>

              <Typography variant="body2" color="textSecondary" component="p">
                Ecommerce Dashboard displays retail and information in useful
                graphs. It uses react with fusion charts and Bootstrap.The data
                is provided through Google sheets API.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a
              href="https://github.com/dragonite20/Ecommerce-Dashboard"
              target="_blank"
            >
              <Button size="small" color="primary">
                Learn More
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <a
                href="https://github.com/dragonite20/Devconnector"
                target="_blank"
              >
                <Typography gutterBottom variant="h5" component="h2">
                 Devconnector
                </Typography>
              </a>
              <Typography variant="body2" color="textSecondary" component="p">
                Devconnector helps developers connect,like,share,comment.
                This Social media app uses react,express & mongoose as well as
                passport.js for authentication.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <a
              href="https://github.com/dragonite20/Devconnector"
              target="_blank"
            >
              <Button size="small" color="primary">
                Learn More
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>
      
    </Grid>
  )
}
